'use client';

import { useEffect } from 'react';

import { useStoreReferrer } from '@/hooks/store-referrer.hook';

const Component: React.FC = () => {
  const { storeReferrer } = useStoreReferrer();

  useEffect(() => {
    storeReferrer();
  }, [storeReferrer]);

  return null;
};

export default Component;
