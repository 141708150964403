import { useSearchParams } from 'next/navigation';
import { useCallback } from 'react';

export const useStoreReferrer = () => {
  const searchParams = useSearchParams();

  /**
   * LocalStorageに以下のリファラー情報を保存する
   * 1. 初回訪問日時
   * 2. utmパラメーター（すべて存在したら上書き保存）
   */
  const storeReferrer = useCallback(() => {
    const isFirstVisit = !!localStorage.getItem('first_visited_at');

    if (!isFirstVisit) {
      localStorage.setItem('first_visited_at', new Date().toISOString());
    }

    const utmParamKeys = ['utm_source', 'utm_medium', 'utm_campaign'];

    // すべてのパラメーターが存在する場合のみ更新する
    if (utmParamKeys.every(key => searchParams.has(key))) {
      utmParamKeys.forEach(key => {
        // 既に値が存在するかチェック済み
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        localStorage.setItem(key, searchParams.get(key)!);
      });
    }
  }, [searchParams]);

  const getReferrer = useCallback(() => {
    const firstVisitedAt = localStorage.getItem('first_visited_at');
    const source = localStorage.getItem('utm_source');
    const medium = localStorage.getItem('utm_medium');
    const campaign = localStorage.getItem('utm_campaign');

    return {
      firstVisitedAt,
      source,
      medium,
      campaign,
    };
  }, []);

  return {
    getReferrer,
    storeReferrer,
  };
};
